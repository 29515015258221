<template>
    <el-row id="projects" class="projects">
        <el-row type="flex" justify="center">
            <el-col :span="15">
                <feature-head text="客户案例"/>
            </el-col>
        </el-row>
        <el-row type="flex" justify="center">
            <el-col :span="20">
                <el-row class="projects-row row-flex" type="flex" justify="space-between">
                    <el-col :sm="24" :md="6" class="projects-list">
                        <el-tabs class="projects-tabs" tab-position="left" v-model="activeIndex" @tab-click="handleClick">
                            <el-tab-pane class="tab-item" label="在芜湖" name="1"></el-tab-pane>
                            <el-tab-pane class="tab-item" label="欣源商贸" name="2"></el-tab-pane>
                            <el-tab-pane class="tab-item" label="信用芜湖" name="3"></el-tab-pane>
                            <el-tab-pane class="tab-item" label="好行网" name="4"></el-tab-pane>
                            <el-tab-pane class="tab-item" label="智慧工地管控平台" name="5"></el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :md="17" class="project-detail">
                        <project-detail />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
    import FeatureHead from "@/components/common/FeatureHead";
    import ProjectDetail from "@/views/projects/childrenViews/ProjectDetail";
    export default {
        name: "Projects",
        data(){
          return {
            activeName: "project-1",
            activeIndex: this.$route.params.id,
          }
        },
        components: {
            FeatureHead,
            ProjectDetail
        },
        methods: {
            handleClick(){
                this.$router.push({name: "project-detail", params: {id: this.activeIndex}})
            },
            resetPath(){
                this.$router.push("1")
                this.activeIndex = "1"
            }
        },
        mounted(){
            this.resetPath()
        }
    }
</script>

<style scoped>
    .projects .projects-row {
        margin-bottom: 30px;
    }

    .projects .projects-list .projects-tabs {
        width: 100%;
    }

    .projects >>> .el-tabs--left .el-tabs__header.is-left {
        width: 100%;
    }

    .projects .projects-list .projects-tabs >>> .el-tabs__item {
        text-align: left;
    }
</style>
